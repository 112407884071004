<template>
  <p>Personen Daten</p>
  <div class="row-start-2 row-span-3">
    <div>
      <span v-if="personisequal.name !== true">Name</span>
      <span v-if="personisequal.label !== true">Bezeichnung</span>
      <span v-if="personisequal.relations !== true">Verwandschaft</span>
      <span v-if="personisequal.relnotes !== true">Verwandschaft (Notizen)</span>
      <span v-if="personisequal.sameas !== true">Gleichgesetzt</span>
      <span v-if="personisequal.state !== true">Status</span>
    </div>
  </div>

  <button @click="savePerson"
          className="hover:bg-red-200 outline-none mr-4 border-2 rounded-md border-red-600 p-1 mt-auto mb-auto w-20">Speichern</button>
</template>

<script>

import {personStore} from "@/stores/person";
import {mapState} from "pinia";
export default {
  name: "SavePerson.vue",
  emits:['done', 'error'],
  props:['personisequal', 'save'],
  data(){
    return{
      oldpersonData : null,
      diffresult : null,

    }
  },

  computed: {
    personStore: function () { return personStore(); },
    ...mapState(personStore, [
      'active_data',
      'savepersondatadone',
      'deleterelationsdone',
      'saverelationsdone',
      'savesameasdone'
    ]),


  },


  watch: {

    save: function () {
      if (this.save === true) {
        console.log("start save Person")
        this.savePerson();
      }
    },


    saverelationsdone(newvalue){
      if(newvalue === true && this.savepersondatadone === true && this.deleterelationsdone === true && this.savesameasdone === true){
        this.saveDone();
      }
    },

    savepersondatadone(newvalue){
      console.log("save perosn data done");
      if( newvalue === true &&this.saverelationsdone === true && this.deleterelationsdone === true && this.savesameasdone === true){
        this.saveDone();
      }
    },
    deleterelationsdone(newvalue){
      console.log("delete relations done");
      if(newvalue === true && this.saverelationsdone === true && this.savepersondatadone === true  && this.savesameasdone === true){
        this.saveDone()
      }
    },

    savesameasdone(newvalue){
      if(newvalue === true && this.saverelationsdone === true && this.savepersondatadone === true && this.deleterelationsdone === true){
        this.saveDone()
      }
    }
  },

  created() {
    /*
    this.oldpersonData = JSON.parse(sessionStorage.getItem('persondata'))
    if(!_.isEqual(this.personData.relations, this.oldpersonData.relations)){
      console.log("relations unterschiedlich")
      this.diffresult = true
    }
    else{
      console.log("Relations gleich")
      this.saverelationsdone = true
      this.deleterelationsdone = true
    }
    this.comparepersondata()


    if(!_.isEqual(this.personData.sameas, this.oldpersonData.sameas)){
      this.diffresult = true
    }
    else{
      this.savesameasdone = true
    }
    */
  },

  methods: {
    savePerson: function() {
      this.personStore.saveData();
    },


    saveDone: function () {
      if (this.saveerror !== true) {
        this.$emit("done");
      } else {
        this.$emit("error")
      }
    },

    /*
    comparesameas(){
      if(!_.isEqual(this.personData.sameas, this.oldpersonData.sameas)){
        console.log("same as ist anders")
        this.savesameasdaten()
      }
    },


  comparerelations() {
      const saverelations = [];
      const deleterelations = [];

      let newrelations = this.personData.relations;
      let oldrelations = this.oldpersonData.relations;
      for (const relation of oldrelations){
        console.log(relation.id)
        console.log(newrelations.some(rel => rel.id === relation.id))
        if(!newrelations.some(rel => rel.id === relation.id)){

          deleterelations.push(this.deleterelation(relation.id));
        }
      }
      Promise.all(deleterelations).then((results) =>{
        console.log(results)
        this.deleterelationsdone = true
      });

      for (const newrelation of JSON.parse(JSON.stringify(this.personData.relations))) {
       console.log(newrelation )
        if (oldrelations.some(relation => relation.id === newrelation.id))
        {
          let oldrelation = oldrelations.find(relation => relation.id === newrelation.id)
          if (!_.isEqual(oldrelation, newrelation)) {
            const data = {'persid': this.personData.id, 'relation': newrelation}
            saverelations.push(this.savenewrelation(data))
          }
        }
        else{
          const data = {'persid': this.personData.id, 'relation': newrelation}
          saverelations.push(this.savenewrelation(data))
        }
      }

      Promise.all(saverelations).then((results) =>{
        console.log(results)
        this.saverelationsdone = true
      })


   // this.comparerelation(newrelations.length, 0, oldrelations, newrelations)

  },
    comparepersondata(){
    let newpersdata = JSON.parse(JSON.stringify(this.personData))
    delete newpersdata.relations
    let oldpersdata = JSON.parse(JSON.stringify(this.oldpersonData))
    delete oldpersdata.relations
    if(!_.isEqual(newpersdata, oldpersdata)){
      this.diffresult = true
    }
    else{
      console.log("keine unterschiede persondata")
      this.savepersondatadone = true
    }
  },

  async savepersonendaten() {
      console.log("start savepersondata")
    let newpersdata = JSON.parse(JSON.stringify(this.personData))
    delete newpersdata.relations
    const data = newpersdata
    axios({
      method: 'post',
      url: this.httpurl + '/update/person',
      headers: {
        "pass" : sessionStorage.getItem('pass'),
        "name" : sessionStorage.getItem('userName')
      },
      data: data,
    }).then(response =>{
      console.log(response)
      if("error" in response.data){
        this.saveerror = true
      }
      this.savepersondatadone = true
    })
  },

  savenewrelation(data){
   return axios({
      method: 'post',
      url: this.httpurl + '/update/person/relation',
      headers: {
        "pass" : sessionStorage.getItem('pass'),
        "name" : sessionStorage.getItem('userName')
      },
      data: {
        data: data,
      }
    }).then(response =>{
     console.log(response.data)
     if('error' in response.data){
       this.saveerror = true
       return { 'id': data.id, 'status': 'error', 'response': response}
     }
     else {
       return {'id': data.id, 'status': 'changed', 'response': response}
     }
   })
  },


    deleterelation(relationref){
      const data = {
        'persid': this.personData.id,
        'relationid': relationref
      }
      return axios.request({
        method: 'post',
        url: this.httpurl + '/delete/person/relation',
        headers:{

          "pass" : sessionStorage.getItem('pass'),
          "name" : sessionStorage.getItem('userName')
        },
        data:{
          data
        }
      }).then(
          response => {
            console.log(response.data)
            if('error' in response.data){
              this.saveerror = true
              return { 'id': relationref, 'status': 'error', 'response': response}
            }
            else {
              return {'id': relationref, 'status': 'deleted', 'response': response}
            }
          }
      )
    },

    async savesameasdaten() {
      console.log("start sameassave")
      const data = {"sameasid": this.personData.sameas.id, "persid": this.personData.id}
      axios({
        method: 'post',
        url: this.httpurl + '/update/sameas',
        headers: {
          "pass" : sessionStorage.getItem('pass'),
          "name" : sessionStorage.getItem('userName')
        },
        data: data,
      }).then(response =>{
        console.log(response)
        if("error" in response.data){
          this.saveerror = true
        }
        this.savesameasdone = true
      })
    },
    



     */

  diff(obj1, obj2) {
    const result = {};
    if (Object.is(obj1, obj2)) {
      return undefined;
    }
    if (!obj2 || typeof obj2 !== 'object') {
      return obj2;
    }
    Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
      if(obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];

      }
      if(typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = this.diff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }

      result['relid'] = obj2['id']
    });
    return result;
  },


  },
}
</script>



<style scoped>

</style>