<template>
    <p class="font-bold">Regest {{ index }}:</p>
   <p v-if="regest.asksam"><span class="font-bold">AskSam: </span>{{ regest.asksam}}</p>
  <p v-if="regest.id"><span class="font-bold">ID: </span>{{ regest.id}}</p>

  <p>{{ regest.text }}</p>
    <p><span class="font-bold ">Datum (Original): </span>{{ regest.date.oridate}}</p>
    <p v-if="regest.date.normalizeddate.type == 'when'">
      <span class="font-bold ">Datum: </span>{{ regest.date.normalizeddate.when }}
    </p>
  <p v-else>
    <span class="font-bold ">Datum: </span>{{ regest.date.normalizeddate.from }} - {{ regest.date.normalizeddate.to }}
  </p>

  <p v-if="regest.date.note"><span class="font-bold ">Notizen zum Datum: </span>{{ regest.date.note }}</p>
    <div v-for="bibl in regest.bibls" :key="bibl">
      <p><span class="font-bold ">Quelle: </span>{{ bibl.source }}</p>
      <p><span class="font-bold ">Typ: </span>{{ bibl.type }}</p>
      <p><span class="font-bold ">Schreinsbezirk: </span>{{ bibl.district.name }}</p>
      <p v-if="bibl.note"><span class="font-bold ">Anmerkung: </span>{{ bibl.note }}</p>
    </div>
    <p v-if="regest.places.length > 0" class="font-bold ">Topo:</p>
    <p v-for="place in regest.places" :key="place">
      <span v-if="place.text" class="font-bold pl-8">regesttext: </span>{{ place.text }}
      <br />
      <span v-if="place.keussen " class="font-bold pl-8">Keussen: </span>{{ place.keussen }}
    </p>

    <p v-if="regest.legalterms.length >0" class="font-bold ">Rechtsbegriffe: </p>
    <p v-for="legalterm in regest.legalterms" :key="legalterm">{{ legalterm }}</p>

    <span v-if="regest.convents.length >0" class="font-bold">Konvente:</span>
  <p v-for="convent in regest.convents" :key="convent">
    <span>{{ convent.name}}</span>
    <br />
  </p>
    <h4 v-if="regest.regestnotes " className='pr-2 float-left font-bold'>Notizen:</h4>
    <p>{{ regest.regestnotes }}</p>
</template>

<script>
import {personListStore} from "@/stores/personList";
import {mapState} from "pinia";

export default {

  name: "RegestenPreview",
  props: ['regest', 'index'],

  personListStore: function (){
    return personListStore();
  },
  ...mapState(personListStore,[
    'personlist'
  ]),
  }

</script>

<style scoped>

</style>