<template>
    <div id='namen' className='p-1 mt-2 grid gap-4 ml-4 pb-2'>
        <span className="flex flex-row justify-between border-b-2 border-gray-300 pb-2">
            <h3 className='mt-auto mb-auto font-semibold'>Person:</h3>
            <span>
                <button type="button"
                    className="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-red-600 p-1 mt-auto mb-auto w-20"
                    @click="goTo()">Zurück</button>
                <!--Popup lädt, aber goBack wird direkt danach ausgefüht (auf Popup legen hinterher?)-->
                <button type="button" @click="toggleDeletePopup()"
                    className="hover:bg-red-200 outline-none mr-4 border-2 rounded-md border-red-600 p-1 mt-auto mb-auto w-20">Löschen</button>
                 <button type="button" :class="colorborderclassRelButtons(personStore.active_data.type)" @click="savePerson('person')">Anwenden</button>
            </span>

        </span>
        <!--
        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label for='num' className='mt-auto mb-auto  font-semibold'>AskSam ID:</label>
            <input type='text' className='mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3' id='num'
                name='num' v-model="nr" />
            <div></div>
        </div>
-->

        <!-- Was soll in dem Feld aufgeführt werden? Nummer/Name? -->

        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label for='fname' className='mt-auto mb-auto font-semibold'>Identisch mit:</label>
            <input @input="autocompletepersons" type='text' className='mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3 border-red-600' id='fname'
                name='ident' v-model="personStore.active_data.sameas.name"/>
          <div id="autocompleteresult" className="max-h-24 overflow-scroll col-start-2 col-end-5">
            <ul>
              <li @click="selectPerson($event)" className="hover:bg-gray-200" v-for="(person, index) in personsearchresult"
                  :key="person" v-bind:id="'personresult_' + index" v-bind:data-asksamnr="person.entrynr"
                  v-bind:data-id="person.id" v-bind:data-name="person.name">{{ person.name }}
                ({{
                  person.entrynr }})
              </li>
            </ul>
          </div>
        </div>

        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label for='fname' className='mt-auto mb-auto font-semibold'>Vorname:</label>
            <input type='text' className='mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3' id='fname'
                name='fname' v-model="personStore.active_data.name.forname" />
            <div></div>
        </div>

        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label for='lastName' className='mt-auto mb-auto  font-semibold'>Nachname:</label>
            <input type='text' className='mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3' id='lastName'
                name='lastName' v-model="personStore.active_data.name.surnames[0].text" />
        </div>
        <!-- SURNAMEVARIANTEN PART -->
        <div id="varNames" className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label className='mt-auto mb-auto  font-semibold'>Varianten:</label>
            <div className="grid grid-flow-row gap-2">
                <div className="mt-auto mb-auto" v-for="(variant, index) in personStore.active_data.name.surnames"
                    :key="index">
                    <span className="flex flex-row" v-if="variant.type !== 'original' || index !== 0">
                        <input  type='text'
                            className='mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3'
                            :id='"surname_" + index' name='lastName'
                            v-model.trim="personStore.active_data.name.surnames[index].text" />
                        <select  class="mr-4 border-2 rounded-md border-teal-600 p-1 mt-auto mb-auto"
                            v-model="personStore.active_data.name.surnames[index].type">
                            <option value="original">Quelle</option>
                            <option value="norm">Normalisierung</option>
                            <option value="prof">Norm. Handwerk</option>
                            <option value="place">Norm. Ort</option>
                        </select>
                        <button v-on:click="personStore.active_data.name.surnames.splice(index, 1)" type="button"
                            :class="colorborderclassButtons(personStore.active_data.type)"><span class="mx-2"></span>-<span
                                class="mx-2"></span></button>
                    </span>
                </div>
            </div>
            <button v-on:click="personStore.active_data.name.surnames.push({text:'', type:''})" type="button" id="ml-4"
                :class="colorborderclassVarButtons(personStore.active_data.type)"><span class="mx-2"></span>+<span
                    class="mx-2"></span></button>
        </div>

        <div id="houses" className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label className='mt-auto mb-auto  font-semibold'>Familie:</label>
            <select :class="colorborderclassFamSelect(personStore.active_data.type)" v-model="personStore.active_data.name.family">
              <option v-for="key in families" :key="key" :value="key">{{ key }}</option>
            </select>

        </div>

        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label for='notename' className='mt-auto mb-auto  font-semibold'> Notizen zum Namen:</label>
            <textarea rows="4" :class="colorborderclassNotes(personStore.active_data.type)" id='notename' name='notename'
                v-model="personStore.active_data.name.namenote" />
        </div>

      <div id="varLabel" className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
        <label for='bez' className='mt-auto mb-auto  font-semibold'>Bezeichnung:</label>
        <input type='text' className='mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-3' id='bez'
               name='bez' v-model="personStore.active_data.label" />
      </div>





      <div id="varLabel" className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
        <label for='bez' className='mt-auto mb-auto  font-semibold'>Bibliographie:</label>
        <div className="grid grid-flow-row gap-2 ">
          <div className="mt-auto mb-auto col-span-5" v-for="(bibl, index) in personStore.active_data.bibliographie"
               :key="index">
                    <span className="grid-cols-subgrid flex flex-row colspan-3">
                        <input  type='text'
                                className='mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto'
                                v-model="personStore.active_data.bibliographie[index]" />
                        <button v-on:click="personStore.active_data.bibliographie.splice(index, 1)" type="button"
                                :class="colorborderclassButtons(personStore.active_data.type)"><span class="mx-2"></span>-<span
                            class="mx-2"></span></button>
                    </span>
          </div>
          <button v-on:click="personStore.active_data.bibliographie.push('')" type="button" id="ml-4"
                  :class="colorborderclassVarButtons(personStore.active_data.type)"><span class="mx-2"></span>+<span
              class="mx-2"></span></button>
        </div>
      </div>


        <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <label for='notes' className='mt-auto mb-auto  font-semibold'>Allgemeine Notizen:</label>
            <textarea rows="4" :class="colorborderclassNotes(personStore.active_data.type)" id='notes' name='notes'
                v-model="personStore.active_data.note" />
        </div>



        <span className='grid grid-cols-7 grid-flow-row'>
            <label className="mt-auto mb-auto font-semibold">Verwandte:</label>

            <button v-on:click="toggleRelationPopup()" type="button"
                :class="colorborderclassButtons(personStore.active_data.type)">Verwandte
                hinzufügen</button>
        </span>
        <div v-for="(rel, index) in personStore.active_data.relations" :key="rel" v-bind:id="'relation_' + index"
            className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
            <!--col-start-2 ??-->
            <span className="grid grid-cols-2 grid-flow-col gap-2 col-span-5 lg:col-span-4">
                <select :class="colorborderclassSelect(personStore.active_data.type)" v-bind:id="'relationselect_' + index"
                    v-model="rel.type" required>
                    <option value="" selected disabled>{{ rel.type }}</option>
                    <option value="parent">Elternteil</option>
                    <option value="sibling">Geschwisterteil</option>
                    <option value="child">Kind</option>
                    <option value="spouse">Ehepartner</option>
                    <option value="relatives">Verwandte</option>
                </select>
                <span type='text' className='mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto' id='relation'
                      name='realtion'>{{personListStore.getPersonNameOverId(rel.id)}}</span>
            </span>
            <button @click="personStore.removeRelation(index)" type="button"
                :class="colorborderclassRelButtons(personStore.active_data.type)">Entfernen</button>

            <button @click="changeEditor(rel.id)" type="button" :class="colorborderclassRelButtons(personStore.active_data.type)">
                Editieren
            </button>

        </div>

      <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
        <label className="mt-auto mb-auto font-semibold break-all">Verwandts. Notizen: </label>
        <textarea rows="4" :class="colorborderclassNotes(personStore.active_data.type)" v-model="personStore.active_data.relnotes" />
      </div>

      <div className="grid grid-cols-7 grid-flow-row pt-3 gap-2">
        <label className="mt-auto mb-auto font-semibold break-all">Editor Notizen: </label>
        <textarea rows="4" :class="colorborderclassNotes(personStore.active_data.type)" v-model="personStore.active_data.editornotes" />
      </div>

        <DeleteTemplate v-if="showDeletePopup" @close-popup="toggleDeletePopup()" :person-data="personStore.active_data"
            :person="true" />
        <AddPersonTemplate v-if="showRelationPopup" @add-person="addRelation" @close-popup="toggleRelationPopup()"/>

        <SaveTemplate v-if="onsave" :savemode="savemode" @close-popup="this.onsave = false"
                      @savedone="this.onsave = false" @saveerror="abortSave" />
    </div>
</template>
<script>
//third Party
import {mapState} from "pinia";

//components
import DeleteTemplate from '../popuptemplates/DeleteTemplate.vue';
import SaveTemplate from "../saves/SaveTemplate.vue";
import AddPersonTemplate from "../popuptemplates/AddPersonTemplate.vue";
import {families} from "@/constanten";

//stores
import {personListStore} from "@/stores/personList";
import {personStore} from "@/stores/person";

export default {
    name: "PersonEditor.vue",
    components: { SaveTemplate, AddPersonTemplate, DeleteTemplate },
    data() {
        return {
          families: families,

          loaded: false,
          savemode: null,
          personsearchstring: "",
          personsearchresult: "",

            showPersonPopup: false,
            showDeletePopup: false,
            showRelationPopup: false,
            showSavedPopup: false,
            activePerson: false,
            activeRelation: false,
            activeDelete: false,
            activeSaved: false,
            onsave: false,
            awaitechange: false,
            changeurl: "",
        }
    },
    emits: ['changeeditor'],


    computed:{

      // Storedefinition for personList
      personListStore: function (){
        return personListStore();
      },
      ...mapState(personListStore,[
        'personlist'

      ]),

      //storedefinition for personStore
      personStore: function (){
        return personStore();
      },
      ...mapState(personStore,[
        'temp_data'
      ]),
    },

    watch: {
        onsave() {
            if (this.onsave == false && this.awaitechange == true) {
              this.$router.push({ path: this.changeurl });
            }
        },

      'personstore.active_data.sameas.name': function (newVal){
        if(newVal === ''){
          this.personStore.sameas.id = ''
        }
      }
    },

    methods: {

        toggleRelationPopup() {
            this.showRelationPopup = !this.showRelationPopup;
            this.activeRelation = !this.activeRelation;
        },
        toggleDeletePopup() {
            this.showDeletePopup = !this.showDeletePopup;
            // this.activeDelete = !this.activeDelete;
        },
        togglePersonPopup() {
            this.showPersonPopup = !this.showPersonPopup;
            this.activePerson = !this.activePerson;
        },
        toggleSavedPopup() {
            this.showSavedPopup = !this.showSavedPopup;
            this.activeSaved = !this.activeSaved;
        },

        showInfos() {
            var infos = document.getElementById("infos");

            infos.style.display = "block";
        },
        hideInfos() {
            var infos = document.getElementById("infos");

            infos.style.display = "none";
        },
        goBack() {
            this.$router.go(-1)
        },
        colorborderclassButtons(type) {
            let classstring = 'hover:bg-gray-200 outline-none mr-4 border-2 rounded-md p-1 mt-auto mb-auto w-fit col-start-5 lg:col-start-4 '
            if (type === 'begine') { classstring += 'border-teal-600' }
            else { classstring += 'border-cyan-500' }
            return classstring
        },
        colorborderclassAddButtons(type) {
            let classstring = 'hover:bg-gray-200 outline-none mr-4 border-2 rounded-md p-1 mt-auto mb-auto w-fit col-start-6 lg:col-start-4 '
            if (type === 'begine') { classstring += 'border-teal-600' }
            else { classstring += 'border-cyan-500' }
            return classstring
        },
        colorborderclassVarButtons(type) {
            let classstring = 'hover:bg-gray-200 outline-none mr-4 lg:ml-20 ml-6 border-2 rounded-md p-1 mt-auto mb-auto w-fit col-start-6 lg:col-start-4 '
            if (type === 'begine') { classstring += 'border-teal-600' }
            else { classstring += 'border-cyan-500' }
            return classstring
        },
        colorborderclassRelButtons(type) {
            let classstring = 'hover:bg-gray-200 outline-none mr-4 border-2 rounded-md p-1 mt-auto mb-auto w-fit '
            if (type === 'begine') { classstring += 'border-teal-600' }
            else { classstring += 'border-cyan-500' }
            return classstring
        },
        colorborderclassNotes(type) {
            let classstring = 'mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto col-span-5 lg:col-span-4 '
            if (type === 'begine') { classstring += 'border-teal-600' }
            else { classstring += 'border-cyan-500' }
            return classstring
        },
        colorborderclassSelect(type) {
            let classstring = 'mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto '
            if (type === 'begine') { classstring += 'border-teal-600' }
            else { classstring += 'border-cyan-500' }
            return classstring
        },
        colorborderclassFamSelect(type) {
            let classstring = 'mr-4 border-2 rounded-md border-gray p-1 mt-auto mb-auto w-fit '
            if (type === 'begine') { classstring += 'border-teal-600' }
            else { classstring += 'border-cyan-500' }
            return classstring
        },


        addRelation: function (relative) {
        let newrelationobject = { 'id': relative.id, 'label':'', 'type': '' };
        this.personStore.active_data.relations.push(newrelationobject);
        this.personStore.resetTempData();
        this.toggleRelationPopup();

        },



        openPopup() {
            document.getElementById("addrelationpopup").style.display = "block";
            document.getElementById("black-bg").style.display = "block";
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        openDeletePopup() {
            document.getElementById("adddeletepopup").style.display = "block";
            document.getElementById("black-bg").style.display = "block";
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },

        closePopup() {
            var relPopup = document.getElementById("addrelationpopup");
            var delPopup = document.getElementById("adddeletepopup")

            if (relPopup.style.display === "block")
                relPopup.style.display = "none";
            else if (delPopup.style.display === "block")
                delPopup.style.display = "none";
            document.getElementById("black-bg").style.display = "none";
        },

        enableDelButton() {
            var delCheck = document.getElementById("delCheck");
            var delButton = document.getElementById("delButton");

            if (delCheck.checked) {
                delButton.disabled = false;
            }
            else {
                delButton.disabled = true;
            }
        },

        changeEditor(id) {
            this.onsave = true
            this.changeurl = '/editor/person/' + id
            this.awaitechange = true
            this.savemode = 'all';

        },


        savePerson: function (savemode) {
        this.savemode = savemode;
        this.onsave = true;
          },

        abortSave: function() {
            this.awaitechange = false;
            this.onsave = false;
        },

        goTo: function() {
          this.onsave = true;
          this.changeurl = '/';
          this.awaitechange = true;
          this.savemode = 'all';
        },

    },

}
</script>
<style scoped>
/* @media screen and (max-width: 900px) {
    #ml-4{
        margin-left: 16px;
    }
}
#ml-4{
    margin-left: 75px;
} */
</style>